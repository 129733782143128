import { Exposure, ExposureTrackingProvider } from '@amplitude/experiment-js-client'
import amplitude, { AmplitudeClient } from 'amplitude-js'

export const initAmplitude = (): void => {
  if (process.env.AMPLITUDE_API_KEY && process.env.AMPLITUDE_API_KEY !== 'DEBUG') {
    try {
      amplitude.getInstance().init(process.env.AMPLITUDE_API_KEY)
    } catch (error) {
      //Silent fail as can't log. Just don't want amplitude errors to impact the UI
    }
  }
}

export const setAmplitudeUserId = (userId: string): void => {
  if (
    process.env.AMPLITUDE_API_KEY &&
    process.env.AMPLITUDE_API_KEY !== 'DEBUG' &&
    process.browser
  ) {
    try {
      amplitude.getInstance().setUserId(userId)
    } catch (error) {
      //Silent fail as can't log. Just don't want amplitude errors to impact the UI
    }
  }
}

export type AmplitudeProperties = Record<string, string | number | undefined | boolean>

export const sendAmplitudeEvent = (
  eventType: string,
  eventProperties?: AmplitudeProperties,
): void => {
  if (
    process.env.AMPLITUDE_API_KEY &&
    process.env.AMPLITUDE_API_KEY !== 'DEBUG' &&
    process.browser
  ) {
    try {
      amplitude.getInstance().logEvent(eventType, eventProperties)
    } catch (error) {
      //Silent fail as can't log. Just don't want amplitude errors to impact the UI
    }
  }
}

export class AmplitudeExposureTrackingProvider implements ExposureTrackingProvider {
  instance: AmplitudeClient

  constructor(instance: AmplitudeClient) {
    this.instance = instance
  }

  track(exposure: Exposure): void {
    this.instance.logEvent('$exposure', exposure)
  }
}
export enum AmplitudeEventTypes {
  CreateMedication = 'Create Medication',
  EditMedication = 'Edit Medication',
  CreateMedicalCondition = 'Create Medical Condition',
  EditMedicalCondition = 'Edit Medical Condition',
  CreateAllergy = 'Create Allergy',
  EditAllergy = 'Edit Allergy',
  PatientRecordsPageLoad = 'Patient Records Page Load',
  CompleteConsult = 'Complete Consult',
  ReturnConsultToPool = 'Return Consult To Pool',
  MarkConsultAsNonUrgent = 'Mark Consult As Non Urgent',
  VerificationAccepted = 'Verification Accepted',
  VerificationRejected = 'Verification Rejected',
  ProviderMetricsLoad = 'Provider Metrics Load',
  ProviderClickAISnippetConfirm = 'Provider Click AI Snippet Confirm',
  SoapAddended = 'Soap Addended',
  SoapOpenPDF = 'Soap Open PDF',
  SoapViewOpen = 'Soap View Open',
  SoapSignedAndSubmitted = 'Soap Signed and Submitted',
  JoinCall = 'Join Video Call',
  ProviderViewedLab = 'Provider viewed lab',
  ProviderSubmittedLabReview = 'Provider submitted lab review',
  ProviderClickPrescribeConsultBar = `provider-click-prescribe-consult-bar`,
  ProviderClickRecommendedMed = 'provider-click-recommended-med',
  ProviderClickCurrentMed = 'provider-click-current-med',
}

export enum AmplitudeFeatureFlagKeys {
  ShowAISnippets = 'show-ai-snippets',
  SOAPBillingItem = 'soap-billing-item',
  DisplayPhotonUI = 'display-photon-ui',
  AsyncPhotonPrescriptions = 'async-photon-prescriptions',
  CareCallsActionQueue = 'care-calls-action-queue',
}
