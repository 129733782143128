import React, { useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'
import styled, { css } from 'styled-components'
import { CloseIcon } from '../CloseIcon'
import Alignment from '../Alignment'
import Button from '../Button'

interface ModalProps {
  children: React.ReactElement
  close: () => void
  type: 'centered' | 'fullscreen'
  title?: string
}

const StyledButton = styled(Button)`
  padding: 0;
  margin: 0;
`

const Title = styled.div`
  color: ${props => props.theme.colors.darkGreen};
  font-weight: bold;
  padding-bottom: 32px;
`

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.3);
  overflow-y: auto;
`

const Centered = css`
  position: absolute;
  padding: 32px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 50%;
`

const FullScreen = css`
  height: 100%;
`

const ModalContent = styled.div<{ type: ModalProps['type'] }>`
  background: white;
  border-radius: 5px;
  z-index: 1;
  ${props => (props.type === 'centered' ? `${Centered}` : `${FullScreen}`)};
`

const Modal = ({ children, close, title, type }: ModalProps) => {
  const modalNode: React.MutableRefObject<null | any> = useRef(null)

  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent> | MouseEvent) => {
    if (modalNode && modalNode.current) {
      if (modalNode.current.contains(e.target)) {
        return
      } else {
        close()
      }
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', e => handleClick(e))
    return () => {
      document.removeEventListener('mousedown', e => handleClick(e))
    }
  }, [])

  return ReactDOM.createPortal(
    <Overlay className="modal">
      <ModalContent ref={modalNode} onClick={e => handleClick(e)} type={type}>
        <Alignment justifyContent="space-between" alignItems="flex-start">
          <Title>{title}</Title>
          <StyledButton type="button" onClick={close}>
            <CloseIcon />
          </StyledButton>
        </Alignment>
        {children}
      </ModalContent>
    </Overlay>,
    document.getElementById('modal-root') as HTMLElement,
  )
}

export default Modal
